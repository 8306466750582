import "./App.css";
import Header from "./components/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/scss/responsive.scss";
import Footer from "./components/Footer";
import { Home } from "./pages/home/Home";
import AllRoutes from "./allRoutes/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import { useContext } from "react";
import { MyContext } from "./Contex/LoadingContex";
import Loader from "./components/Loader";

function App() {
  const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  const [errorPage, setErrorPage] = useState(false);
  // console.log("value of is loading is : ",isLoadingMain)
  return (
    <>
      { errorPage === true ? (
        <Header pageNoteFoundBackground={"page-note-found-header"} />
      ) : isLoadingMain ? (
       <Loader />
      ) : (
        <Header  />
      )}

      <AllRoutes errorPage={errorPage} setErrorPage={setErrorPage} />
      {errorPage === true ? (<Footer />):isLoadingMain ? <></> : <Footer />}
      {/* {<Footer />} */}
      <ToastContainer />
    </>
  );
}

export default App;
