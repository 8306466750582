import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useQuery } from "react-query";
import { getSingleKanifushiOfferData } from "../../services/dataServices";
import innerCover from "../../assets/images/offers-details-cover.jpg";
// import largeOffer from '.././assets/images/lowest-price-assurance.jpg'
import { Link, useParams } from "react-router-dom";

import Helmet from "../../components/Helmet";
import PageNotFound from "../../components/PageNotFound";
import BannerImage from "../../components/BannerImage";
import { useState } from "react";
function OfferDetail({setErrorPage,slugOfOffers}) {
  const [isBannerLoading, setIsBannerLoading] = useState(true);

  const { offerId } = useParams();
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  let singleOfferData = useQuery(
    ["getSingleKanifushiOfferData", +offerId],
    () => getSingleKanifushiOfferData(offerId)
  );
  // const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  const { isLoading, isSuccess } = singleOfferData;
  if(singleOfferData?.data?.data===404){
  
    return(
      <>
        <PageNotFound setErrorPage={setErrorPage}/>
      </>
    )
  }

  let keywords = singleOfferData?.data?.data?.keywords;
  let slug = singleOfferData?.data?.data?.slug;
  let meta_title = singleOfferData?.data?.data?.meta_title;
  let meta_description = singleOfferData?.data?.data?.meta_description;
  const bannerImgAltTag= singleOfferData?.data?.data?.inner_banner_alt_tag||"";


  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return;
  // }
  // if (isSuccess) {
  //   setIsLoadingMain(false);
  // }
  // const handleImageLoad = () => { setIsLoadingMain(false) };

  // const handleImageLoad = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   setIsLoadingMain(true);
  // }
  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };

  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }


  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100">

            {/* <img
              className="w-100 h-100"
              onLoad={handleImageLoad}
              src={singleOfferData?.data?.data?.inner_banner_image?singleOfferData?.data?.data?.inner_banner_image:innerCover}
              title=""
              alt={bannerImgAltTag}
              onError={handleError}
            /> */}
            
          <BannerImage
              className="w-100 h-100"
              src={singleOfferData?.data?.data?.inner_banner_image?singleOfferData?.data?.data?.inner_banner_image:innerCover}
              title=""
              alt={bannerImgAltTag}
              isDataIsLoading={isLoading}
            />

          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                OFFERS
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/${slugOfOffers}`}>Offers</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
               
                {singleOfferData?.data?.data?.offer_name}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <section>
        <div className="offer-details section-padding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="small-heading text-center mb-4">
                  {singleOfferData?.data?.data?.resort_name} | MALDIVES
                </div>
                <div className="dashed-heading position-relative">
                  <h2 className="georgia text-uppercase text-center mb-0">
                    <span className="px-4">
                      {singleOfferData?.data?.data?.offer_name}
                    </span>
                  </h2>
                </div>
                <h2 className="georgia text-uppercase text-center mb-0 mt-4 fw-bold">
                  {singleOfferData?.data?.data?.discount_text}
                </h2>
              </div>
              <div className="row mt-lg-5 mt-4">
                <div className="col-12">
                  <div className="large-img-offer">
                    <img
                      className="w-100"
                      src={singleOfferData?.data?.data?.offer_image}
                      alt={singleOfferData?.data?.data?.offer_image_alt_tag ||''}
                      title=""
                    />
                  </div>
                  <div className="offer-para mt-4 pt-2">
                    <p>{singleOfferData?.data?.data?.description}</p>
                  </div>
                </div>
              </div>
              <div className="row mt-lg-5 mt-4">
                <div className="col-lg-6 pe-lg-4">
                  <div className="dashed-heading position-relative">
                    <h4 className="georgia text-uppercase mb-0">
                      <span className="pe-3">HIGHLIGHTS</span>
                    </h4>
                  </div>
                  <div className="offer-points mt-lg-4 mt-3">
                    <ul className="m-0">
                      {singleOfferData?.data?.data?.highlights
                        .split("\n")
                        .map((item) => (
                          <li>
                            <span>{item}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 ps-lg-4 mt-lg-0 mt-md-5 mt-4">
                  <div className="dashed-heading position-relative">
                    <h4 className="georgia text-uppercase mb-0">
                      <span className="pe-3">TERMS & CONDITIONS</span>
                    </h4>
                  </div>
                  <div className="offer-points mt-lg-4 mt-3">
                    <ul className="m-0">
                      {singleOfferData?.data?.data?.terms_conditions
                        .split("\n")
                        .map((item) => (
                          <li>
                            <span>{item}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="col-12 mt-xl-5 mt-4">
                  <div className="offer-book-action">
                    <Link
                      to={singleOfferData?.data?.data?.website_url}
                      //   to="https://reservations.atmosphere-kanifushi.com/95216?RatePlanId=3606567?utm_source=website&utm_medium=offers&utm_campaign=bookdirectoffer&_gl=1*1psld6p*_gcl_au*MTEyMjgxMzI3My4xNzAxODM5ODgw*_ga*MTc0NTQ1NjkwOS4xNzAxODM5ODgx*_ga_5D7JCP2H7F*MTcwMjM3ODEyNC4yLjEuMTcwMjM3ODEyNC42MC4wLjA.#/guestsandrooms"
                      target="_blank"
                      className="btn primary theme-btn fw-medium text-white text-uppercase border-theme btn btn-primary"
                    >
                      Book Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OfferDetail;
