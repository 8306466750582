import React, { useContext } from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import ExperienceAwait from "../../components/ExperienceAwait";
import DiningCard from "./DiningCard";
import Glimpses from "../../components/Glimpses";
import {
  getAllRestaurants,
  getPageData,
  getPageData1,
} from "../../services/dataServices";
import { useQuery } from "react-query";

// import bannerCover from "../../assets/defaultBannerAndIcon/banner-placehoder.jpg";
// import defaultIcon from "../../assets/defaultBannerAndIcon/icon-placehoder.png";
import Helmet from "../../components/Helmet";

import BannerImage from "../../components/BannerImage";
function DiningList({
  image ,
  isPreview,
  userId,
  sectionId,
  slugOfGallery,
  slugOfDining
}) {
  const pageCode = "VDINING";
  const pagesSectionData1 = useQuery(
    ["pagesSectionData1", pageCode, isPreview, userId, sectionId],
    () => getPageData1(pageCode, isPreview, userId, sectionId)
  );
  // const [isBannerLoading, setIsBannerLoading] = useState(true);
  // const { isLoadingMain, setIsLoadingMain } = useContext(MyContext);
  const { isLoading, isSuccess } = pagesSectionData1;
  // const handleError = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  const bannerImage =
    pagesSectionData1?.data?.sectionData?.filter(
      (item) =>
        item.section_code === "DINING" &&
        item.field_label_code === "BANNERIMAGE"
    )[0]?.psdd_data ;

  const pagesSectionData = useQuery(
    ["pagesSectionData", pageCode, isPreview, userId, sectionId],
    () => getPageData(pageCode, isPreview, userId, sectionId)
  );
  const diningFetchData = pagesSectionData?.data?.data?.map(
    (sectionData) =>
      sectionData?.section_details?.filter(
        (item) => item.section_code === "DINING"
      )[0] ?? null
  );

  // const bannerImage =
  //   diningFetchData?.[0]?.section_elements?.filter(
  //     (item) => item.field_label_code === "BANNERIMAGE"
  //   )[0]?.data ?? bannerCover;

  const bannerImageAltTag =
    diningFetchData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "BANNERIMAGE"
    )[0]?.img_alt_tag ?? null;
  const imageIcon =
    diningFetchData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "ICON"
    )[0]?.data ;
  const heading =
    diningFetchData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "HEADING"
    )[0]?.data ?? null;
  const para =
    diningFetchData?.[0]?.section_elements?.filter(
      (item) => item.field_label_code === "DESCRIPTION"
    )[0]?.data ?? null;

  const Image1 =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE5")[0]
      ?.data ?? null;

  const Image2 =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE2")[0]
      ?.data ?? null;

  const Image3 =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE4")[0]
      ?.data ?? null;

  const Image4 =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE3")[0]
      ?.data ?? null;

  const Image5 =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE6")[0]
      ?.data ?? null;

  const Image6 =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE1")[0]
      ?.data ?? null;

  const topLeftImagealt =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE1")[0]
      ?.img_alt_tag ?? null;

  const topRightImagealt =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE5")[0]
      ?.img_alt_tag ?? null;

  const bottomLeftImagealt =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE2")[0]
      ?.img_alt_tag ?? null;

  const bottomCenterImagealt =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE4")[0]
      ?.img_alt_tag ?? null;

  const topCenterImagealt =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE3")[0]
      ?.img_alt_tag ?? null;

  const bottomRightImagealt =
    diningFetchData?.[0]?.subSectionData
      .filter((item) => item.sub_section_title_code === "DGLIMPSES")[0]
      ?.elements.filter((item) => item.field_title_code === "IMAGE6")[0]
      ?.img_alt_tag ?? null;

  // const para = '<p>Much like the diversity of our palm-fringed island, our dining experiences are teeming with unique culinary choices. From authentic Maldivian cuisine to tantalising global flavours to humble vegetarian food with a modern twist, our chefs are delighted to plate for every discerning palate. We’re here to serve gastronomical experiences that make your heart full and appetite satiated but leave your taste buds longing for more. </p>'

  // const heading = 'Plating for every discerning palate'

  const diningListData = useQuery("getDiningList", getAllRestaurants);
  const allRestaurantsList = diningListData?.data?.data;

  const keywords = pagesSectionData?.data?.data[0]?.meta_keyword;
  const slug = pagesSectionData?.data?.data[0]?.slug;
  const meta_title = pagesSectionData?.data?.data[0]?.meta_title;
  const meta_description = pagesSectionData?.data?.data[0]?.meta_description;

  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return;
  // }
  // if (isSuccess) {
  //   setIsLoadingMain(false);
  // }
  // const handleImageLoad = () => {
  //   console.log("on load hitting")
  //   setIsLoadingMain(false)

  // };

  // const handleImageLoad = () => {
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };
  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   return <Loader />;
  // } else if (isBannerLoading) {
  //   setIsLoadingMain(true);
  // }

  // const handleImageLoad = () => {
  //   document.body.classList.remove('slider-open');
  //   setIsBannerLoading(false);
  //   setIsLoadingMain(false);
  // };

  // if (isLoading) {
  //   setIsLoadingMain(true);
  //   document.body.classList.add('slider-open');
  //   return <Loader />;
  // }
  // else if (isBannerLoading) {
  //   document.body.classList.add('slider-open');
  //   setIsLoadingMain(true);
  // }
  return (
    <>
      <Helmet
        keywords={keywords}
        slug={slug}
        metaDescription={meta_description}
        title={meta_title}
      />
      <section>
        <div className="inner-cover header-space position-relative">
          <div className="our-story position-absolute start-0 top-0 h-100 cover-overlay">


          <BannerImage
              className="w-100 h-100"
              src={bannerImage}
              title=""
              alt={bannerImageAltTag}
              isDataIsLoading={isLoading}
            />
            {/* <img
              className="w-100 h-100"
              src={bannerImage}
              onLoad={handleImageLoad}
              onError={handleError}
              title=""
              alt={bannerImageAltTag}
            /> */}
          </div>
          <div className="page-inner position-relative text-center">
            <div className="page-title d-flex justify-content-center">
              <h2 className="text-uppercase text-white georgia py-xl-4 py-3 px-5">
                DINING
              </h2>
            </div>
            <Breadcrumb className="d-flex justify-content-center mt-4 text-white">
              <Breadcrumb.Item>
                <Link to="/">Home</Link>{" "}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>Dining</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </section>

      <ExperienceAwait
        showLargeheading={true}
        image={imageIcon}
        heading={heading}
        para={para}
      />

      <section>
        <div className="our-dining-list">
          <div className="container">
            <div className="row">
              {allRestaurantsList?.map((data) => {
                const tags = data.restaurant_tags.filter(
                  (item) => item !== null && item.trim() !== ""
                );

                const type =
                  data.restaurant_type && data.restaurant_type.length != null
                    ? data.restaurant_type
                    : "";
                const diningImgAltImg = data?.image_alt_tag;

                return (
                  <DiningCard
                    key={data.restaurant_id}
                    restaurantId={data.restaurant_id}
                    diningImage={data.restaurant_image }
                    catDining={type}
                    diningName={data.restaurant_name}
                    // diningTime={"Dinner"}
                    diningDes={data.restaurant_description}
                    diningMenuLink={data.restaurant_menu_image}
                    restTagline={data.restaurant_tagline}
                    diningLinkTarget={"_blank"}
                    showMenu={"Show Menu"}
                    learnMoreLink={`/restaurant-details/${data.restaurant_id}`}
                    slug={`/${slugOfDining}/${data.slug}`}
                    learnMore={"Learn More"}
                    diningcards={
                      data.color_code === null || data.color_code === ""
                        ? "#7281d0"
                        : data.color_code
                    }
                    endTime={true}
                    // endDiningTime={"Lunch 21.30 - 14.30"}
                    tags={tags}
                    diningImgAltImg={diningImgAltImg}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <Glimpses
        image1={Image1}
        image2={Image2}
        image3={Image3}
        image4={Image4}
        image5={Image5}
        image6={Image6}
        bottomLeftImagealt={bottomLeftImagealt}
        topRightImagealt={topRightImagealt}
        bottomCenterImagealt={bottomCenterImagealt}
        topCenterImagealt={topCenterImagealt}
        bottomRightImagealt={bottomRightImagealt}
        topLeftImagealt={topLeftImagealt}
        slugOfGallery={slugOfGallery}
      />
    </>
  );
}

export default DiningList;
